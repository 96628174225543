import Link from "next/link"
import React from "react"

import AuthPlaceholderImage from "@icons/AuthPlaceholder"
import AuthPlaceholderSubImage from "@icons/AuthPlaceholders"
import routes from "@mono/essentials/constants/routes"
import Background from "@public/svg/background.svg"

const InfoSection = () => (
  <div className={"flex flex-col justify-center items-center"}>
    <div>
      <Background className={"object-cover overflow-hidden opacity-30 mix-blend-soft-light"} />
    </div>

    <div className={"block md:hidden"}>
      <Link
        href={routes.register}
        className={
          "ml-auto flex justify-end rounded-lg px-4 py-2 text-sm font-medium text-white hover:underline dark:text-blue-500"
        }
      >
        <div className={"rounded-[10px] border p-2"}>Create Account</div>
      </Link>
    </div>

    <div className={"absolute right-0 top-40"}>
      <div className={"w-[485px]"}>
        <AuthPlaceholderImage />
        <div className={"relative"}>
          <AuthPlaceholderSubImage
            className={
              "absolute bottom-[-80px] left-[-7%] rounded-[10px] lg:left-[-5%]  xl:left-[-17%]"
            }
          />
        </div>
      </div>
    </div>

    <div className={"absolute bottom-40 text-center"}>
      <h5 className={"text-white text32Semibold"}>SmartRez Booking</h5>
      <h6 className={"text-white text24Semibold"}>to Manage and Grow Your Business</h6>
    </div>
  </div>
)

export default InfoSection
